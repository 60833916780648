.navbar {
    /* position: fixed; */
    width: 85%;
    height: 83px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-top: 1%;
    /* background-color: red; */
    /* z-index: 100; */
}

a {
    text-decoration: none;
}


.logo {
    width: 114px;
    height: 83px;
    left: 121px;
    top: 30px;
}

.log {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 114px;
    height: 83px;
    left: 121px;
    top: 30px;
}

.totc {
    position: absolute;
    z-index: 2;
    width: 92px;
    height: 48px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 48px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}

.pol {
    z-index: 1;
    position: absolute;
    width: 63px;
    height: 63px;
    border: 2px solid #65DAFF;
    border-radius: 6px;
    transform: rotate(50deg);
}

.right {
    display: flex;
    align-items: center;
    align-content: center;
}

nav {
    width: 321px;
    display: flex;
    justify-content: space-between;
    margin-right: 81px;
}

nav a {
    font-size: 18px;
    color: white;
    text-transform: capitalize;
    transition: .4s;
}

nav a:hover {
    transform: scale(0.8);
}

.buttons {
    display: flex;
    justify-content: space-around;
    width: 346px;
    height: 61px;
}

@media screen and (max-width: 930px) {

    nav {
        width: 160.5px;
        display: flex;
        justify-content: space-between;
        margin-right: 40.5px;
    }

    nav a {
        font-size: 10px;
        color: white;
        text-transform: capitalize;
        transition: .4s;
        margin-left: 5px;
    }

}


.button {
    position: relative;
    width: 160px;
    height: 60px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.03);
    border-radius: 80px;
    margin: 1%;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0.02em;
    color: white;
    border: none;
    cursor: pointer;
    line-height: 59px;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    transition: 0.5s;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.button:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
}

.button:hover {
    background-color: white;
    color: #5B5B5B;
    border-bottom: 4px solid white;
}

.button:hover:before {
    transform: skewX(-45deg) translateX(13.5em);
    transition: all 0.5s ease-in-out;
}

.menuButton {
    display: none;
    font-size: 50px;
    color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.menuButton:hover {
    color: #65DAFF;
}




@media screen and (max-width: 680px) {
    .navbar {
        width: 75%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        margin-top: 1%;
    }

    nav {
        display: none;
    }

    .menuButton {
        display: block;
    }

    .logo {
        width: 57px;
        height: 41.5px;
        left: 60.5px;
        top: 15px;
    }

    .log {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 57px;
        height: 41.5px;
        left: 60.5px;
        top: 15px;
    }

    .totc {
        position: absolute;
        z-index: 2;
        width: 46px;
        height: 24px;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
    }

    .pol {
        z-index: 1;
        position: absolute;
        width: 31.5px;
        height: 31.5px;
        border: 1px solid #65DAFF;
        border-radius: 6px;
        transform: rotate(50deg);
    }

    .right {
        width: 85%;
        display: flex;
        align-items: center;
        align-content: center;
    }

    .buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        margin-left: 15%;
    }

    /*  */

    .button {
        width: 80px;
        height: 30px;
        box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.03);
        font-size: 11px;
        letter-spacing: 0.01em;
        line-height: 29.5px;
        border: 0.5px solid rgba(255, 255, 255, 0.2);
    }

    .wrapper {
        margin: 0 1%;
    }

    .button:hover {
        border-bottom: 2px solid white;
    }
}