.aboutTotc {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    width: 45%;
    cursor: default;
    margin-top: 10%;
}


.title {
    display: flex;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.title p {
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    color: #F48C06;
}

.title p span {
    color: white;
}

.main {
    width: 100%;
    margin-top: 2%;
}

.main p {
    /* width: 100%; */
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #FFFFFF;
}

.btns {
    width: 100%;
    margin-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}


.icon {
    width: 60px;
    height: 60px;
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: #FFFFFF;
    box-shadow: 2px 20px 60px rgba(61, 155, 185, 0.1);
    border-radius: 50%;
    cursor: pointer;
    transition: .4s;
    margin: 0 5%;
}

.icon:hover {
    transform: scale(0.9);
}

.button {
    position: relative;
    width: 160px;
    height: 60px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.03);
    border-radius: 80px;
    margin: 1%;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0.02em;
    color: white;
    border: none;
    cursor: pointer;
    line-height: 59px;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    transition: 0.5s;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.button:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
}

.button:hover {
    background-color: white;
    color: #5B5B5B;
    border-bottom: 4px solid white;
}

.button:hover:before {
    transform: skewX(-45deg) translateX(13.5em);
    transition: all 0.5s ease-in-out;
}


.triangle {
    font-size: 26px;
    color: #23BDEE;
    border-radius: 1px;
    margin: 0 5%;
}


.text {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #252641;
}

@media screen and (max-width: 1400px) {

    .main {
        width: 100%;
        margin-top: 1%;
    }

    .aboutTotc {
        width: 85%;
        margin-top: 5%;
    }

}

@media screen and (max-width: 680px) {
    .aboutTotc {
        width: 95%;
        margin-top: 10%;
    }

    .title p {
        font-size: 27px;
    }

    .main p {
        font-size: 12px;
    }


    .triangle {
        font-size: 13px;
    }


    .icon {
        width: 30px;
        height: 30px;
        min-width: 30px;
        box-shadow: 1px 10px 30px rgba(61, 155, 185, 0.1);
    }

    /*  */

    .button {
        width: 80px;
        height: 30px;
        box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.03);
        font-size: 11px;
        letter-spacing: 0.01em;
        line-height: 29.5px;
        border: 0.5px solid rgba(255, 255, 255, 0.2);
    }

    .button:hover {
        border-bottom: 2px solid white;
    }

}