 * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 }
body {
   font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    display: flex;
    justify-content: center;
}


